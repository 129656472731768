var fancyIn = false;
var cerrarFancy = true;
var acabado = 0;
var sliderCreated = false;
var headerFixed = false;
var prevScroll = 0;
var keyCodeMap = {
        48:"0", 49:"1", 50:"2", 51:"3", 52:"4", 53:"5", 54:"6", 55:"7", 56:"8", 57:"9", 59:";",
        65:"a", 66:"b", 67:"c", 68:"d", 69:"e", 70:"f", 71:"g", 72:"h", 73:"i", 74:"j", 75:"k", 76:"l",
        77:"m", 78:"n", 79:"o", 80:"p", 81:"q", 82:"r", 83:"s", 84:"t", 85:"u", 86:"v", 87:"w", 88:"x", 89:"y", 90:"z",
        96:"0", 97:"1", 98:"2", 99:"3", 100:"4", 101:"5", 102:"6", 103:"7", 104:"8", 105:"9"
}

// header control to fixed position menu element multidevices
function headerFix() {
    if (window.outerWidth > 739){
    	if ($('body').hasClass('index')){
    		var heightCar = $('#carousel-home').height() + 62;
	        if ($(window).scrollTop() > 120 && !headerFixed) {
	        	headerFixed = true;
	            $('#header').animate({
				    top: -120
				}, 100,'swing', function() {
				    $('#header').addClass('fixed').animate({top:0},400,'swing');
				});
	        } else if ($(window).scrollTop() < 120 && headerFixed){
	        	headerFixed = false;
	            $('#header').animate({
				    top: -120
				}, 100,'swing', function() {
				    $('#header').removeClass('fixed').css({top:0});
				});
	        }
	        if ($(window).scrollTop() > heightCar && !$('.fixed-onscroll').hasClass('fixed')) {
				$('.fixed-onscroll').addClass('fixed').css('top','58px');
	        } else if ($(window).scrollTop() < heightCar && $('.fixed-onscroll').hasClass('fixed')){
				$('.fixed-onscroll').removeClass('fixed').css('top','');
	        }
    	} else {
	        if ($(window).scrollTop() > 120 && !headerFixed) {
	        	headerFixed = true;
	            $('#header').animate({
				    top: -120
				}, 100,'swing', function() {
				    $('#header').addClass('fixed').animate({top:0},400,'swing');
				});
				$('.sub-header').animate({
				    top: 0
				}, 100,'swing', function() {
				    $('.sub-header').addClass('fixed').animate({top:58},400,'swing');
				});
	        } else if ($(window).scrollTop() < 120 && headerFixed){
	        	headerFixed = false;
	            $('#header').animate({
				    top: -120
				}, 100,'swing', function() {
				    $('#header').removeClass('fixed').animate({top:0},400,'swing');
				});
				$('.sub-header').animate({
				    top: 0
				}, 100,'swing', function() {
				    $('.sub-header').removeClass('fixed').animate({top:120},400,'swing');
				});
	        }   		
    	}
    } else {
    	$('#header').css('top',0);
    	if ($(window).scrollTop() > 20 && $(window).scrollTop() > prevScroll) {
    		$('#header').addClass('fixed');
    	} else {
    		$('#header').removeClass('fixed');
    	}
    	if ($(window).scrollTop() > 100 && $(window).scrollTop() < prevScroll){
    		$('#goTop').fadeIn(400);
    	} else {
    		$('#goTop').fadeOut(400);
    	}
    	prevScroll = $(window).scrollTop();
    }
}

// move lateral submenu onscroll in page 
function lateralTop() {
    if (window.outerWidth > 1023){

        if ($(window).scrollTop() > 120) {
	    	hMax = $('#contenido >.row .col-md-9').height();
	    	hLat = $('#lateral').height();
        	if (hMax > hLat + $(window).scrollTop()-50){
	            $('#lateral').css("margin-top", $(window).scrollTop()-70);
        	} else {
	            $('#lateral').css("margin-top", hMax > hLat + $(window).scrollTop()-50);
        	}

        } else {
        	$('#lateral').css("margin-top", 0);
        }
    } else {
        $('#lateral').css("margin-top", 0);
    }
}
$(window).on('scroll resize', function () {
    headerFix();
    lateralTop();
});
$(window).trigger('scroll');

function init(){
	if ($('#carousel-home').length){
		var swiperHome = new Swiper('#carousel-home', {
	        pagination: '.shome-pagination',
	        slidesPerView: 'auto',
	        paginationClickable: true,
	        autoplay: 6000,
	        centeredSlides:true,
	        loop:true,
	        a11y: true,
	        prevSlideMessage: 'Mostrar la pantalla anterior',
	        nextSlideMessage:'Mostrar la pantalla siguiente',
	        firstSlideMessage: 'Es la primera pantalla',
	        lastSlideMessage: 'Es la última pantalla',
	        paginationBulletMessage: 'Mostrar pantalla {{index}}',
	        keyboardControl: true,
	        nextButton: '.shome-next',
	        prevButton: '.shome-prev',
	        onInit: function (swiper) {
	            $('#carousel-home').addClass('ready');
	        }
	    });
	    $(document).on('click','#carousel-home .btn-autoplay', function(){
	    	if ($(this).hasClass('stop')){
	    		swiperHome.startAutoplay();
	    		$(this).toggleClass('stop');
	    	} else {
	    		swiperHome.stopAutoplay();
	    		$(this).toggleClass('stop');
	    	}
	    })
	}

	if ($('#owl-revistas').length){
		var owlrev = new Swiper('#owl-revistas', {
	        slidesPerView: 3,
	        paginationClickable: true,
	        autoplay: 0,
	        a11y: true,
	        prevSlideMessage: 'Mostrar la pantalla anterior',
	        nextSlideMessage:'Mostrar la pantalla siguiente',
	        firstSlideMessage: 'Es la primera pantalla',
	        lastSlideMessage: 'Es la última pantalla',
	        paginationBulletMessage: 'Mostrar pantalla {{index}}',
	        keyboardControl: true,
	        nextButton: '.srev-next',
	        prevButton: '.srev-prev',
		    breakpoints:{
		        600: {
		            slidesPerView: 2
		        },
		        480: {
		            slidesPerView: 1
		        }     
		    },
	        onInit: function (swiper) {
	            $('#owl-revistas').addClass('ready');
	        }
	    });
	}

	if ($('.swiper-videos').length){
	    $(".swiper-videos").each(function() {
		  	if ($(".swiper-videos .swiper-slide").length > 1){
				var swvideos = new Swiper('.swiper-videos', {
			        slidesPerView: 3,
			        loop:true,
			        centeredSlides:true,
			        paginationClickable: true,
			        autoplay: 0,
			        followFinger:true,
			        a11y: true,
			        keyboardControl: true,
			        spaceBetween: 10,
                    observer: true,
			        effect:"fade",
			        keyboardControl: true,
			        onInit: function (swiper) {
			            $('.swiper-videos').addClass('ready');
			        }
			    });
			    $(document).on('click','.swiper-videos .swipe-video-next',function(){
			    	swvideos.slideNext();
			    });
			    $(document).on('click','.swiper-videos .swipe-video-prev',function(){
			    	swvideos.slidePrev();
			    });
		  	} else {
				var swvideos = new Swiper('.swiper-videos', {
			        slidesPerView: 1,
			        loop:false,
			        centeredSlides:true,
			        paginationClickable: false,
			        autoplay: 0,
			        followFinger:true,
			        a11y: false,
			        effect:"fade",
			        onInit: function (swiper) {
			            $('.swiper-videos').addClass('ready');
			        }
			    });		  	
			}
		});
	}

	$('.dropdown').setup_navigation();
	
	var $grid;

	if($('.masonGrid').length>0){
		    
		var $images = $('.masonGrid img');
		var loaded_images_count = 0;
		$grid = $('.masonGrid').isotope({
	        itemSelector : '.grid-item',
	        percentPosition: true,
	        masonry: {
	            columnWidth: '.grid-sizer',
	            gutter: 21
	        },
	        sortBy: 'random'
	    });

		$images.load(function(){
		    loaded_images_count++;
			//console.log(loaded_images_count);
		    if (loaded_images_count == $images.length) {
				$grid.isotope('reloadItems').isotope();
	            setTimeout(function(){
					$('.masonGrid').addClass("show")
				},800)
		    }
		}).each(function() {
		  if(this.complete) $(this).load();
		});;
	}	

	// fancybos accesibles
  	$('.fancybox').fancybox({
		helpers: { 
	        title: null
	    },
	    tpl:{
			closeBtn : '<a id="cerrarFancy" class="fancybox-item fancybox-close" href="#"><span class="text-hide">Cerrar</span></a>'
	    },
  		afterShow: function () {
	        $('.fancybox-wrap').attr("aria-hidden",false);
	        $('#wrapper, #aside-mobile').attr("aria-hidden",true);
	        $(this.content).attr("tabindex",-1)	
	        fancyIn = true;
	    },
  		afterClose: function () {
	        $('.fancybox-wrap').attr("aria-hidden",true);
	        $('#wrapper, #aside-mobile').attr("aria-hidden",false);
	        $(this.content).attr("tabindex",0);
	        fancyIn = false;
	    }
	});

  	// fancybox accesibles para videos
  	$('.video').fancybox({
		'width': 720,
		'height': 405,
		'padding':30,      
		helpers: { 
	        title: null,
	        media : {}
	    },
	    tpl:{
			closeBtn : '<a id="cerrarFancy" class="fancybox-item fancybox-close" href="#"><span class="text-hide">Cerrar</span></a>'
	    },
  		afterShow: function () {
	        $('.fancybox-wrap').attr("aria-hidden",false);
	        $('#wrapper, #aside-mobile').attr("aria-hidden",true);
	        $(this.content).attr("tabindex",-1)	
	        fancyIn = true;
	    },
  		afterClose: function () {
	        $('.fancybox-wrap').attr("aria-hidden",true);
	        $('#wrapper, #aside-mobile').attr("aria-hidden",false);
	        $(this.content).attr("tabindex",0);
	        fancyIn = false;
	    }	
	});

  	// fancybox accesibles para popups
	$(".popup").fancybox({
        'type' : 'iframe',
		'maxWidth' : 750,
		'maxHeight' : 600,
		helpers: { 
	        title: null
	    },
	    tpl:{
			closeBtn : '<a id="cerrarFancy" class="fancybox-item fancybox-close" href="#"><span class="text-hide">Cerrar</span></a>'
	    },
  		afterShow: function () {
	        $('.fancybox-wrap').attr("aria-hidden",false);
	        $('#wrapper, #aside-mobile').attr("aria-hidden",true);
	        $(this.content).attr("tabindex",-1)	
	        fancyIn = true;
	    },
  		afterClose: function () {
	        $('.fancybox-wrap').attr("aria-hidden",true);
	        $('#wrapper, #aside-mobile').attr("aria-hidden",false);
	        $(this.content).attr("tabindex",0);
	        fancyIn = false;
	    }    
	});

	//custom file type
	$(":file").filestyle({'icon': false,'buttonText': 'Seleccionar <span class="icon icon-circle-right"></span>'});
    
    //custom select accesible
    $("select").selectmenu();

	//comportamiento swipe en carousels bootstrap
	$(".carousel").swiperight(function() {  
		$(this).carousel('prev');  
	});  
	$(".carousel").swipeleft(function() {  
		$(this).carousel('next');  
	});
}

$(function(){
	Modernizr.load;
	resize();
	init();
	//desplegado de información total reducida en mobile en páginas de producto
	$(document).on("click",".btn-minfo",function(){
		var id = $(this).attr('data-target');
		$(id).removeClass('reduceText');
		$(this).remove();
	}); 
    
    $(document).on("click","#closeC",function(){
    	//politica de cookies
    	var csrfToken = $('meta[name="csrf-token"]').attr("content");
        $.post("/ajax/yescoc",{e:"e",_csrf : csrfToken},function(data){
            $('#cookies').slideUp();
        },"json")
    }).on("click","#showMobMenu",function(){
    	//mostrar menu mobile
    	if ($('#aside-mobile').hasClass('open')){
	        $('#aside-mobile').removeClass('open');
	        $('#wrapper, #header .visible-xs .table').removeClass('moved');	
			if (!Modernizr.touch) { 
				setTimeout(function(){
	            	var element = $('.scrollMenu').jScrollPane();
			    	var api = element.data('jsp');
			    	api.destroy();
	            },400);
			}
    	} else {
			$('.scrollMenu').height($(window).height());
			if (!Modernizr.touch) { 
				$('.scrollMenu').jScrollPane({autoReinitialise: true});
			}
	 	    $('#aside-mobile').addClass('open');
	        $('#wrapper, #header .visible-xs .table').addClass('moved');	
    	}
    }).on("click","#btnFormExp",function(){
    	$('#formExpandible').addClass('expanded');
    	$('#formExpandible .form').slideDown();
		if ($(window).outerWidth() < 740){
    		var body = $("html, body");
			body.stop().animate({scrollTop:$('.promo').offset().top-20}, '600', 'swing');
    	}
    });
    /*.on("click","#wrapper",function(){
    	//ocultar menu mobile
    	if ($('#aside-mobile').hasClass('open')){
	        $('#aside-mobile').removeClass('open');
	        $('#wrapper, #header .visible-xs .table').removeClass('moved');	
			if (!Modernizr.touch) { 
				setTimeout(function(){
	            	var element = $('.scrollMenu').jScrollPane();
			    	var api = element.data('jsp');
			    	api.destroy();
	            },400);
			}
    	}
    });*/
});

$(document).delegate('a[href*="#wrapper"]', 'click', function (event) {
    event.preventDefault();
    if ($(window).width() >=740){
      $('body,html').stop().animate({scrollTop:$('#wrapper').offset().top}, '600', 'swing');
    } else {
      $('body,html').stop().animate({scrollTop:$('#formExpandible').offset().top}, '600', 'swing');
    }
});
//controles accesibilidad

$(document).on('keydown', function(e) { 
	var keyCode = e.keyCode || e.which; 
	// key tabs
	if (keyCode == 9) { 
		if (fancyIn){
			e.preventDefault();
			if (cerrarFancy){
				$('#cerrarFancy').focus();
				cerrarFancy = false;

			} else {
				$('.fancybox-iframe').focus();
				cerrarFancy = true;
			}
		}
	} 
}).on("click",".playCarousel",function(){
	// control accesibilidad stop y play en carousels bootstrap
	if (!$(this).hasClass('stop')){
		$('.carousel').carousel('cycle');
		$(this).removeClass('stop').find('.text-hide').html('Parar pasafotos');
	} else {
		$('.carousel').carousel('pause');
		$(this).addClass('stop').find('.text-hide').html('Reproducir pasafotos');
	}	
}).on("click","#goContent",function(){
	// botón para lectores de pantalla -> FOCUS Salta menú de navegación y va directo al contenido
	$('#content').focus().attr('data-hidden', 'false').attr('tabIndex', 0);
}).on("click","#goTop",function(){
	var body = $("html, body");
	body.stop().animate({scrollTop:0}, '600', 'swing');
});


$(window).resize(function(){
	resize();
});

function resize(){
	var w = $(window).width();
	if ($('#aside-mobile').hasClass('open') && $(window).width() >= 740 ){
        $('#aside-mobile').removeClass('open');
        $('#wrapper, #header .visible-xs .table').removeClass('moved');	
	}
}

$.fn.setup_navigation = function(settings) {
	settings = jQuery.extend({
		menuHoverClass: 'openHover',
		menuFocusClass: 'openFocus',
	}, settings);
	
	// Add ARIA role to menubar and menu items
	//$(this).attr('role', 'menubar').find('li').attr('role', 'menuitem');
	
	var top_level_links = $(this).find('> li > .dropdown-toggle');
	
	// Set tabIndex to -1 so that top_level_links can't receive focus until menu is open
	$(top_level_links).next('ul')
		.attr({ 'data-hidden': 'true' })//, 'role': 'menu'
		.find('a')
			.attr('tabIndex',-1);
	
	// Adding aria-haspopup for appropriate items
	/*$(top_level_links).each(function(){
		if($(this).next('ul').length > 0)
			$(this).parent('li').attr('aria-haspopup', 'true');
	});*/
	
	if (!Modernizr.touch) {
		$(top_level_links).parent('li').hover(function(){
			$(this).closest('ul')
				.parent().children('.dropdown-toggle').addClass("active")
				.attr('data-hidden', 'false')
				.find('.'+settings.menuHoverClass)
					.attr('data-hidden', 'true')
					.removeClass(settings.menuHoverClass)
					.removeClass(settings.menuFocusClass)
					.height('')
					.find('a')
						.attr('tabIndex',-1);
			$(this).children('.dropdown-toggle').addClass("active").next('ul')
				.attr('data-hidden', 'false')
				.addClass(settings.menuHoverClass)
				.find('a').attr('tabIndex',0);
			var height = 0;
			$('.'+settings.menuHoverClass+' > li').each(function() {
			  	height += $(this).innerHeight();
			})
			if ($(window).width() >= 740){
				$(this).children('.'+settings.menuHoverClass).height(height);
			} else {
				$(this).children('.'+settings.menuHoverClass).height(height+14);
			}
		}, function() {
			$(this).children('.dropdown-toggle').removeClass("active").next('ul')
				.attr('data-hidden', 'true')
				.removeClass(settings.menuHoverClass).height('')
				.removeClass(settings.menuFocusClass)
				.find('a')
					.attr('tabIndex',-1);
		});
		$(top_level_links).focus(function(){
			$(this).closest('ul')
		      // The following was adding aria-hidden="false" to root ul since menu is never hidden
		      // and seemed to be causing flakiness in JAWS (needs more testing) 
		      // .attr('aria-hidden', 'false') 
		      .find('.' + settings.menuFocusClass)
		      .attr('data-hidden', 'true')
		      .removeClass(settings.menuHoverClass)
		      .removeClass(settings.menuFocusClass)
		      .find('a')
		      .attr('tabIndex', -1);

		    $(this).next('ul')
		      .attr('data-hidden', 'false')
		      .addClass(settings.menuFocusClass)
		      .find('a').attr('tabIndex', 0);
		});
		$('.dropdown-menu .last').focusout(function(){
			$('.dropdown').find('.'+settings.menuFocusClass)
				.height('')
				.attr('data-hidden', 'true')
				.removeClass(settings.menuHoverClass)
				.removeClass(settings.menuFocusClass)
				.find('a')
					.attr('tabIndex',-1);
		});
	}


	if (Modernizr.touch) { 
		$(top_level_links).click(function(){
			var height = 0;
			if ($(this).hasClass("active")){
				$(this).removeClass("active");
				$(this).next('ul')
					.attr('data-hidden', 'true')
					.removeClass(settings.menuHoverClass)
					.removeClass(settings.menuFocusClass)
					.height('')
					.find('a')
						.attr('tabIndex',-1);
			} else {
				//alert('open');
				$('.dropdown')
					.find('.'+settings.menuHoverClass)
						.height('')
						.attr('data-hidden', 'true')
						.removeClass(settings.menuHoverClass)
						.removeClass(settings.menuFocusClass)
						.find('a')
							.attr('tabIndex',-1);
				$('.dropdown').find('.dropdown-toggle.active')
					.removeClass('active');
				$(this).addClass("active");
				$(this).next('ul')
					.attr('data-hidden', 'false')
					.addClass(settings.menuHoverClass)
					.find('a').attr('tabIndex',0);
				
				$('.'+settings.menuHoverClass+' > li').each(function() {
				  	height += $(this).innerHeight();
				})
				if ($(window).width() >= 740){
					$(this).parent().children('.'+settings.menuHoverClass).height(height);
				} else {
					$(this).parent().children('.'+settings.menuHoverClass).height(height+14);
				}
			}
		});
	}
	
	// Bind arrow keys for navigation
	$(top_level_links).keydown(function(e){
		if(e.keyCode == 37) {
			e.preventDefault();
			// This is the first item
			if($(this).parent('li').prev('li').length == 0) {
				$(this).parents('ul').find('> li').last().find('a').first().focus();
			} else {
				$(this).parent('li').prev('li').find('.dropdown-toggle').first().focus();
			}
		} else if(e.keyCode == 38) {
			e.preventDefault();
			if($(this).parent('li').find('ul').length > 0) {
				$(this).parent('li').find('ul')
					.attr('data-hidden', 'false')
					.addClass(settings.menuFocusClass)
					.find('a').attr('tabIndex',0)
						.last().focus();
			}
		} else if(e.keyCode == 39) {
			e.preventDefault();
			// This is the last item
			if($(this).parent('li').next('li').length == 0) {
				$(this).parents('ul').find('> li').first().find('a').first().focus();
			} else {
				$(this).parent('li').next('li').find('.dropdown-toggle').first().focus();
			}
		} else if(e.keyCode == 40) {
			e.preventDefault();
			if($(this).parent('li').find('ul').length > 0) {
				$(this).parent('li').find('ul')
					.attr('data-hidden', 'false')
					.addClass(settings.menuFocusClass)
					.find('a').attr('tabIndex',0)
						.first().focus();
			}
		} else if(e.keyCode == 13 || e.keyCode == 32) {
			// If submenu is hidden, open it
			e.preventDefault();
			$(this).parent('li').find('ul[aria-hidden=true]')
					.attr('data-hidden', 'false')
					.addClass(settings.menuFocusClass)
					.find('a').attr('tabIndex',0)
						.first().focus();
		} else if(e.keyCode == 27) {
			e.preventDefault();
			$('.'+settings.menuFocusClass)
				.attr('data-hidden', 'true')
				.removeClass(settings.menuFocusClass)
				.removeClass(settings.menuHoverClass)
				.find('a')
					.attr('tabIndex',-1);
		} else {
			$(this).parent('li').find('ul[data-hidden=false] a').each(function(){
				if($(this).text().substring(0,1).toLowerCase() == keyCodeMap[e.keyCode]) {
					$(this).focus();
					return false;
				}
			});
		}
	});
	
	
	var links = $(top_level_links).parent('li').find('ul').find('a');
	$(links).keydown(function(e){
		if(e.keyCode == 38) {
			e.preventDefault();
			// This is the first item
			if($(this).parent('li').prev('li').length == 0) {
				$(this).parents('ul').parents('li').find('.dropdown-toggle').first().focus();
			} else {
				$(this).parent('li').prev('li').find('a').first().focus();
			}
		} else if(e.keyCode == 40) {
			e.preventDefault();
			if($(this).parent('li').next('li').length == 0) {
				$(this).parents('ul').parents('li').find('.dropdown-toggle').first().focus();
			} else {
				$(this).parent('li').next('li').find('a').first().focus();
			}
		} else if(e.keyCode == 27 || e.keyCode == 37) {
			e.preventDefault();
			$(this)
				.parents('ul').first()
					.prev('.dropdown-toggle').focus()
					.parents('ul').first().find('.'+settings.menuFocusClass)
						.attr('data-hidden', 'true')
						.removeClass(settings.menuFocusClass)
						.removeClass(settings.menuHoverClass)
						.find('a')
							.attr('tabIndex',-1);
		} else if(e.keyCode == 32) {
			e.preventDefault();
			window.location = $(this).attr('href');
		} else {
			var found = false;
			$(this).parent('li').nextAll('li').find('.dropdown-toggle').each(function(){
				if($(this).text().substring(0,1).toLowerCase() == keyCodeMap[e.keyCode]) {
					$(this).focus();
					found = true;
					return false;
				}
			});
			
			if(!found) {
				$(this).parent('li').prevAll('li').find('.dropdown-toggle').each(function(){
					if($(this).text().substring(0,1).toLowerCase() == keyCodeMap[e.keyCode]) {
						$(this).focus();
						return false;
					}
				});
			}
		}
	});

		
	// Hide menu if click or focus occurs outside of navigation
	$(this).find('a').last().keydown(function(e){ 
		if(e.keyCode == 9) {
			// If the user tabs out of the navigation hide all menus
			$('.'+settings.menuHoverClass)
				.attr('data-hidden', 'true')
				.removeClass(settings.menuHoverClass)
				.find('a')
					.attr('tabIndex',-1);
		}
	});
	$(document).click(function(){ $('.'+settings.menuFocusClass).attr('data-hidden', 'true').removeClass(settings.menuHoverClass).removeClass(settings.menuFocusClass).find('a').attr('tabIndex',-1); });
	
	$(this).click(function(e){
		e.stopPropagation();
	});
}


	