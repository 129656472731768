function historia_car(){
  var owl = new Swiper('#owl-historia', {
      slidesPerView: 3,
      paginationClickable: true,
      a11y: true,
      prevSlideMessage: 'Mostrar la pantalla anterior',
      nextSlideMessage:'Mostrar la pantalla siguiente',
      firstSlideMessage: 'Es la primera pantalla',
      lastSlideMessage: 'Es la última pantalla',
      paginationBulletMessage: 'Mostrar pantalla {{index}}',
      keyboardControl: true,
      nextButton: '.shist-next',
      prevButton: '.shist-prev',
      breakpoints:{
        760: {
            slidesPerView: 2
        },
        400: {
            slidesPerView: 1
        }     
      },
      onInit: function (swiper) {
        $('#owl-historia').addClass('ready');
      }
  });
}
function inArray(needle, haystack) {
  var length = haystack.length;
  for(var i = 0; i < length; i++) {
    if(haystack[i] == needle) return true;
  }
  return false;
}
function filtrarColFaq(){
  $('#listaFaqs').slideUp(function(){
    $('.collapse').removeClass("in");
    var checked = [];
    $('#filtraCol input[type="checkbox"]:checked').each(function(){
      checked.push($(this).val());
    })
    $('#listaFaqs dt').each(function(){
      var grupo = $(this).attr("data-grupoid");
      var grupoSplit = grupo.split(",");
      var resultado = false;
      for(var cont = 0;cont<grupoSplit.length && !resultado;cont++){
        if(inArray( grupoSplit[cont], checked )){
          resultado = true;
        }
      }
      //console.log(resultado);
      if(resultado){
        $(this).show();
      }else{
        $(this).hide();
      }
      $('#listaFaqs').slideDown(function(){
      });
    })
  })
  //console.log(checked);
}

$(function(){
  if($('#filtroMen').length>0){
    $('#filtroMen').on("selectmenuchange",function(){
      document.location = $(this).val();
    })
  }
    //js busqueda.twig    
    $('#paisbuscador').selectmenu({
      change: function() {
        getProvinciasCamposBusc();}
    });
    if($('#busquedaBlock').length==1){
      getBusqueda();
    }
    if($('#filtraCol').length>0){
      filtrarColFaq();
    }

    //js ampliar imagen en producto y otros bloques
    $("a.bAmpliar,a.ampliarImg,a.grande").fancybox({
        helpers: { 
            title: null,
            media : {}
        },
        tpl:{
        closeBtn : '<a id="cerrarFancy" class="fancybox-item fancybox-close" href="#"><span class="text-hide">Cerrar</span></a>'
        },
        afterShow: function () {
            $('.fancybox-wrap').attr("aria-hidden",false);
            $('#wrapper, #aside-mobile').attr("aria-hidden",true);
            $(this.content).attr("tabindex",-1) 
            fancyIn = true;
        },
        afterClose: function () {
            $('.fancybox-wrap').attr("aria-hidden",true);
            $('#wrapper, #aside-mobile').attr("aria-hidden",false);
            $(this.content).attr("tabindex",0);
            fancyIn = false;
        } 
    });

    // js testauditivo.twig
    $('.volver_test').on('click', function() {
      $('#testauditivo_positivo').hide();
      $('#testauditivo_negativo').hide();
      $('#testauditivo_intermedio').hide();
      
      respondidas=0;
      respondidas_si=0;
      respondidas_no=0;
      respondidas_si_control_intermedio_lista='';
      
      $("input:radio").prop('checked',false);
      $("input:radio").removeAttr("checked");
      $(".iradio").removeClass("checked");
      document.getElementById("form_ta").reset();
      
      $('#mensaje').hide();
      $('#testauditivo').fadeIn();
      $("html, body").animate({scrollTop: 170}, 1000); 
      
    });

    // js filtros faqs.twig y colaboradores.twig
    if($('#filtraCol').length>0){
      mostrarCol();
    }

    // js testauditivo.twig
    if($('#owl-historia').length>0){
      historia_car()
    }
})


function enviar_testauditivo() {
  // comprobar todas las respuestas respondidas
  var error=false;
  var respondidas=0;
  var respondidas_si=0;
  var respondidas_no=0;
  var respondidas_si_control_intermedio_lista='';
  
  $('form input').each(function() {
    if ($(this).is(':checked')) {
      respondidas++;
      if ($(this).val()==0) respondidas_no++;
      else {
        respondidas_si++;
        respondidas_si_control_intermedio_lista += $(this).attr('name');
      }
      
    }
    
  });
  
  if (respondidas!=8) {
    $('#mensaje').html('<center>Debes responder a todas las preguntas.</center>');
    $('#mensaje').focus();
    $('#mensaje').fadeIn();
    
    
    $("html, body").animate({scrollTop: 170}, 1000); 
    return;
  }

  if (parseInt(respondidas_si)>=6) {
    abrir_negativo();
  }
  else if (respondidas_si_control_intermedio_lista=='test_2test_3test_4') {
    abrir_intercambio();
  }
  else {
    abrir_positivo();
  }
}

function abrir_positivo() {
  $('#testauditivo').hide();
  $('#testauditivo_positivo').fadeIn();
  $("html, body").animate({scrollTop: 170}, 1000); 
}

function abrir_intercambio() {
  $('#testauditivo').hide();
  $('#testauditivo_intermedio').fadeIn();
  $("html, body").animate({scrollTop: 170}, 1000); 
}

function abrir_negativo() {
  $('#testauditivo').hide();
  $('#testauditivo_negativo').fadeIn();
  $("html, body").animate({scrollTop: 170}, 1000); 
}

//script colabores.twig
//// DESPLEGADO Y CARGA
$(document).on('click','.logos .col-xs', function(){
  var id = $(this).children("a").attr("data-id");
  var lang_id = 1;
    $.get("/ajax/colaborador",{id:id },function(data){
      $('#colaborador').html(data);

      $('.logos').slideUp();
      $('#colaborador').slideDown();
      $('html,body').animate({
           scrollTop: $("#filtraCol").offset().top},
            'slow');
    })
});
////PLEGADO Y REMOVE
$(document).on("click","#cerrarCol",function(){
  $('.logos').slideDown();
  $('#colaborador').slideToggle(function(){
    $(this).html("");
  });
});

// script carga por ajax de colaboradores y faqs según filtros
function mostrarCol(){
  filtro = new Array();
  var cont = 0;
  $('.logos').slideUp(function(){
    $('#colaborador').slideUp(function(){
      $(this).html("");
    });
    $('#filtraCol input').each(function(){
      if($(this).is(":checked")) {
        filtro[cont] = $(this).val();
        cont++;
      }
    });
    $.get("/ajax/getcolaboradores",{filtro:filtro },function(data){
      $('.logos').html(data);
      $('.logos').slideDown();
    });
  });
}

//scripts búsquedas
var paginaBusqueda = 0;
function getBusqueda(){
  var busqueda = $('#busquedaBlock').attr("data-palabra");
  var csrfToken = $('meta[name="csrf-token"]').attr("content");
  $.post("/ajax/getbusqueda",{ busqueda:busqueda, pagina:paginaBusqueda, lang_id : 1, _csrf : csrfToken },function(data){
    if(data.maxPag==paginaBusqueda+1 || data.maxPag==0){
      $('.moreResults').hide();
    }else{
      $('.moreResults').show();
    }
    $('.searchResults').append(data.html);
    $('.totalRegs').text(data.totalRegs);
  },"json");
}
$(document).on("click",".moreResults",function(){
  paginaBusqueda++;
  getBusqueda();
});
$(document).on('click','#showContSearch',function(){
  $('#busquedaForm').fadeIn();
    $('#busq').focus();
});

//script filtro mapa localizador centros
function provVaciaCamposBusc(){
  $('#provincia').html("<option value=''>Provincia</option>");
  $('#provincia').attr("disabled",true);
  $('#provincia').selectmenu( "refresh" );
}
function getProvinciasCamposBusc() {
  var pais = $('#paisbuscador').val();
  if(pais!=""){
    var csrfToken = $('meta[name="csrf-token"]').attr("content");
    $.post("/ajax/getprovincias",{pais:pais,_csrf : csrfToken},function(data){

      if(data.html!=""){
        $('#provincia').html(data.html);
        $('#provincia').attr("disabled",false);
        $('#provincia').selectmenu( "refresh" );
      }else{
        provVaciaCamposBusc();
      }
    },"json");
  }else{
    provVaciaCamposBusc();
  }
}

$(document).on("click",".openmenu",function(){
    $(this).parent("li").addClass("active");
    $(this).parent("li").children("ul").slideDown();
    $(this)
    .addClass("closemenu")
    .removeClass("openmenu")
    .children().children('.accion').html('Plegar');
}).on("click",".closemenu",function(){
  $(this).parent("li").removeClass("active");
  $(this).parent("li").children("ul").slideUp();
  $(this)
  .addClass("openmenu")
  .removeClass("closemenu")
  .children().children('.accion').html('Desplegar');
});